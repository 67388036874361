<template>
  <div class="lg:px-32 px-4 lg:mt-36 mt-28 mb-28 lg:flex lg:justify-evenly lg:items-start">
      <div class="w-full lg:w-6/12 text-left">
          <img src="@/assets/img/logo.png" alt="company logo" class="w-80">
          <h1 class="mt-6 my-3 font-semibold text-xl">PT Dharma Polimetal Tbk</h1>
          <p class="text-base font-medium">
              Head Office : 
              <br>
              Jl. Angsana Raya Blok A9 No.8 Delta Silicon 1 Cikarang 17550 
              <br>
              Telp. (6221) 897 4637 / (6221) 897 4559 
              <br>
              Fax : (6221) 899 01656
          </p>
      </div>
      <div class="w-full lg:w-6/12 md:w-8/12">
            <div class="text-left w-full lg:w-10/12">
                <dropdown 
                    :dtar="data"
                    backgroundColor="bg-secondary"
                    textColor="text-grays-50 font-bold"
                    class="mt-0"
                    title="Pilih Tujuan Email"
                    v-if="lang == 'id'"
                />
                <dropdown 
                    :dtar="data"
                    backgroundColor="bg-secondary"
                    textColor="text-grays-50 font-bold"
                    class="mt-0"
                    title="Choose Email Recipient"
                    v-else
                />
                <div class="my-4">
                    <label for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Email</label>
                    <input type="text" v-model="sender" placeholder="" required class="inputStyle" />
                </div>
                <div class="mb-4">
                    <label v-if="lang == 'id'" for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Nama</label>
                    <label v-else for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Name</label>
                    <input type="text" v-model="name" placeholder="" required class="inputStyle" />
                </div>
                <div class="mb-4">
                    <label v-if="lang == 'id'" for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Subjek</label>
                    <label v-else for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Subject</label>
                    <input type="text" v-model="subject" placeholder="" required class="inputStyle" />
                </div>
                <div class="mb-4">
                    <label v-if="lang == 'id'" for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Pesan Anda</label>
                    <label v-else for="name" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Message</label>
                    <textarea id="w3review" v-model="text" name="w3review" rows="4" cols="50" class="inputStyle"></textarea>
                </div>
                <div class="flex w-80 justify-between">
                    <div v-if="lang == 'id'" class="font-bold text-base p-3 w-36 bg-secondary text-grays-50 text-center cursor-pointer rounded-sm" @click="postData">
                        <span v-if="sending == false">Kirim Pesan</span>
                        <div class="flex justify-center" v-else>
                            <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                            <path fill="#fff" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                                <animateTransform attributeType="xml"
                                attributeName="transform"
                                type="rotate"
                                from="0 25 25"
                                to="360 25 25"
                                dur="0.6s"
                                repeatCount="indefinite"/>
                                </path>
                            </svg>
                        </div>
                    </div>
                    <div v-else class="font-bold text-base p-3 w-36 bg-secondary text-grays-50 text-center cursor-pointer rounded-sm" @click="postData">
                        <span v-if="sending == false">Send Message</span>
                        <div class="flex justify-center" v-else>
                            <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                            <path fill="#fff" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                                <animateTransform attributeType="xml"
                                attributeName="transform"
                                type="rotate"
                                from="0 25 25"
                                to="360 25 25"
                                dur="0.6s"
                                repeatCount="indefinite"/>
                                </path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
      </div>
  </div>
</template>

<script>
import dropdown from "@/components/Dropdown"
import axios from 'axios';
import { mapState } from 'vuex';

export default {
    components: {
        dropdown
    },
    data() {
        return {
            data:[
                { list:"Public Relation & Corp Secretary"},
                { list:"Investor Relation"},
                { list:"Application Letter"},
                { list:"International Sales Enquiry"}
            ],
            sender: "",
            name: "",
            subject: "",
            text: "",
            sending: false
        }
    },
    computed: {
        ...mapState({
			lang: state => state.home.lang ? state.home.lang :'id'
        }),
    },
    methods: {
        postData() {
            var bodyFormData = new FormData();
            bodyFormData.append('receiver', 'company@email.com');
            bodyFormData.append('sender', this.sender);
            bodyFormData.append('name', this.name);
            bodyFormData.append('subject', this.subject);
            bodyFormData.append('text', this.text);

            axios({
                method: "post",
                url: "https://api.dharmagroup.co.id/api/contact/send",
                data: bodyFormData,
                headers: { 'Authorization': 'Bearer 71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53'},
            })
            .then(function (response) {
                //handle success
                console.log(response);
                this.sender = ""
                this.name = ""
                this.subject = ""
                this.text = ""
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                this.sender = ""
                this.name = ""
                this.subject = ""
                this.text = ""
            });

            this.sending = true

            setTimeout(() => { 
                this.sending = false
                this.sender = ""
                this.name = ""
                this.subject = ""
                this.text = ""
            }, 1000);
        }
    }
}
</script>

<style>
    .inputStyle{
        @apply w-full px-3 py-2 placeholder-gray-300 rounded-sm focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 bg-grays-100;
        border:1px solid #1749E029;
    }
</style>